<!-- 学习课程 -->
<template>
	<div class="study-page">
		<div class="nav-content">
			<div class="nav-title flex-ac">
				<div class="back flex-jc-ac" @click="back">&lt; | 返回</div>
				<div class="chapte-title">{{ title }}</div>
			</div>
		</div>

		<div class="course-content-wrap">
			<div class="course-content">
				<div class="title-contianer flex-jb">
					<div class="left">
						<div class="title">{{ courseInfo.ksname }}</div>
						<div class="info flex-ac">
							<div class="view flex-ac">
								<img src="../assets/image/icon-eye.png" alt="" class="icon" />
								<span>{{ courseInfo.ksllnum }}</span>
							</div>
							<div class="view flex-ac" @click="changeCollectStatus">
								<img v-show="courseInfo.sctype == 2" src="../assets/image/icon-praise3.png" alt="" class="icon praise" />
								<img v-show="courseInfo.sctype == 1" src="../assets/image/icon-praise2.png" alt="" class="icon praise" />
								<span>{{ courseInfo.ksuscnum }}</span>
							</div>
						</div>
					</div>
					<div class="right">
						<div class="time">学习时长: {{ courseInfo.kstime }}分钟</div>
						<div class="flex-ac">
							<div class="step-wrap">
								<div class="step" :style="{ width: courseInfo.timebili + '%' }"></div>
							</div>
							<span>{{ courseInfo.timebili }}%</span>
						</div>
					</div>
				</div>
				<div class="video-container" v-if="courseInfo.occvideo">
					<video
						@contextmenu.prevent
						:class="{ hide: courseInfo.timebili != 100 }"
						id="player"
						controls="controls"
						:src="courseInfo.occvideo"
						controlsList="nodownload"
						disablePictureInPicture
					></video>
				</div>
				<div class="btn-wrap flex-jc" v-if="courseInfo.zgttype == 2 || courseInfo.kgttype == 2 || courseInfo.dcwjtype == 2">
					<div class="btn active" v-if="courseInfo.zgttype == 2" @click="goToSubjectivePage">问答题</div>
					<div class="btn active" v-if="courseInfo.kgttype == 2" @click="goToObjectivePage(1)">选择题</div>
					<div class="btn active" v-if="courseInfo.dcwjtype == 2" @click="goToObjectivePage(2)">调查问卷</div>
				</div>
				<div v-if="courseInfo.imagtjsurlarr && courseInfo.imagtjstype == 2">
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide" v-for="(item, index) in courseInfo.imagtjsurlarr" :key="index">
								<img class="swiper_img" :src="item" alt="" />
							</div>
						</div>
						<!-- 如果需要导航按钮 -->
						<div class="swiper-button-prev swiper_prev"></div>
						<div class="swiper-button-next swiper_next"></div>
					</div>
					<div class="btn-list">
						<div class="prev-btn pdf-btn" :class="{ prohibit: activeSwiperIndex == 0 }" @click.stop="prevPage">上一页</div>
						<div class="pagination">{{activeSwiperIndex + 1}} / {{courseInfo.imagtjsurlarr.length}}</div>
						<div class="next-btn pdf-btn" :class="{ prohibit: activeSwiperIndex + 1 == courseInfo.imagtjsurlarr.length }" @click.stop="nextPage">下一页</div>
					</div>
				</div>
				<div class="pdf-file-container" v-if="courseInfo.pdfurltype == 2 && courseInfo.infopdfurl">
					<div class="pdf-file">
						<div class="pdf-prev" @click.stop="prevPagePDF"></div>
						<pdf :src="courseInfo.infopdfurl" :page="pdfPageNum" @num-pages="pdfTotalPageNum = $event"></pdf>
						<div class="pdf-next" @click.stop="nextPagePDF"></div>
					</div>

					<div class="btn-list">
						<div class="prev-btn pdf-btn" @click.stop="prevPagePDF">上一页</div>
						<div class="pagination">{{pdfPageNum}} / {{pdfTotalPageNum}}</div>
						<div class="next-btn pdf-btn" @click.stop="nextPagePDF">下一页</div>
					</div>
				</div>

				<div class="introduction-container" v-if="courseInfo.occinfo">
					<!-- <div class="title">简介</div> -->
					<div class="content" v-html="courseInfo.occinfo"></div>
				</div>

				<div class="material-list-wrap" v-if="materialList.length > 0 && courseInfo.kszltype == 2">
					<div class="material-title">资料下载</div>
					<div class="material-list">
						<div class="item flex-ac" v-for="(item, index) in materialList" :key="index">
							<div class="title flex1">
								<span>{{ index + 1 }}.{{ item.mname }}</span>
								<span v-if="item.createtime">({{ formatTime(item.createtime) }})</span>
							</div>
							<a target="_blank" :download="item.title" v-if="item.kcfile" :href="item.kcfile"><div class="btn">下载</div></a>
						</div>
					</div>
				</div>
				<!-- <div class="pdf-file-container" v-if="courseInfo.ispdfshowwz == 2 && courseInfo.pdfurltype == 2">
					<div class="pdf-file">
						<div class="pdf-prev" @click.stop="prevPage"></div>
						<pdf :src="courseInfo.infopdfurl" :page="pdfPageNum" @num-pages="pdfTotalPageNum = $event"></pdf>
						<div class="pdf-next" @click.stop="nextPage"></div>
					</div>

					<div class="btn-list">
						<div class="prev-btn pdf-btn" @click.stop="prevPage">上一页</div>
						<div class="next-btn pdf-btn" @click.stop="nextPage">下一页</div>
					</div>
				</div> -->
			</div>
		</div>
		<!-- 评论 -->
		<!-- <div class="comment-container" v-if="courseInfo.iswdplshow != 2">
			<div class="nav-content">
				<div class="comment-nav flex-jb-ac">
					<div class="title">评论</div>
					<div class="comment-btn" @click="handleClickCommentBtn">我要评论</div>
				</div>
			</div>
			<div class="comment-wrap">
				<div class="comment-content">
				
					<div class="my-comment" v-show="showComment">
						<div class="rate-wrap flex-jc">
							<div class="rate flex-ac" v-for="item in kswdlist" :key="item.id">
								<span>{{ item.wdname }}</span>
								<el-rate v-model="item.average" :disabled="courseInfo.ksuser_wdtype == 2"></el-rate>
							</div>
						</div>
						<div class="my-textarea-wrap flex-jc">
							<textarea maxlength="200" v-model="courseComment" :disabled="!canSubmitCourseCommit" class="textarea" placeholder="您有什么想说的呢？"></textarea>
							<div class="comment-btn" @click="submitCommit">评论</div>
						</div>
					</div>
					
					<div class="course-rate rate-wrap flex-jb-ac flex-wrap">
						<div class="rate flex-jc-ac" v-for="item in courseInfo.kswdlist" :key="item.id">
							<span>{{ item.wdname }}</span>
							<el-rate disabled v-model="item.average"></el-rate>
						</div>
					</div>
					
					<div class="comment-list">
						<div class="comment flex" v-for="(item, listIndex) in commentList" :key="item.id">
							<img :src="item.userlogo" alt="" class="avatar" />
							<div class="content">
								<div class="info flex-jb">
									<div class="name">{{ item.cname }}</div>
									<div class="options flex-ac">
										<div class="praise flex-ac" @click="changePraise(item)">
											<img v-show="item.kcc_dz_type == 0" src="../assets/image/icon-praise1.png" alt="" />
											<img v-show="item.kcc_dz_type == 1" src="../assets/image/icon-praise2.png" alt="" />
											<span>{{ item.like }}</span>
										</div>
										<div class="detele" v-if="userInfo.id == item.sid" @click="handleDetele(item.id, listIndex)">删除</div>
									</div>
								</div>
								<pre class="txt" v-html="item.content"></pre>
								<div class="rate-wrap flex">
									<div class="rate flex-ac" v-for="(i, index) in item.wdlist" :key="index">
										<span>{{ i.wdame }}</span>
										<el-rate disabled v-model="i.wdf"></el-rate>
									</div>
								</div>
								<div class="time-wrap flex-jb-ac">
									<div class="time">{{ item.createtime }}</div>
									<div class="reply" @click="showReplyBox(item, item.id)">回复</div>
								</div>
								<div class="reply-list-container flex" v-for="(i, index) in item.hflist" :key="index">
									<img :src="i.userlogo" alt="" class="replyer-avatar" />
									<div class="reply-list">
										<div class="reply-content">
											<span class="name">{{ i.cname }}</span>
											回复
											<span class="name">{{ i.hfname }}</span>
											&nbsp;&nbsp;&nbsp;&nbsp;
	
											<pre class="reply-txt">{{ i.content }}</pre>
										</div>
										<div class="time">{{ i.createtime }}</div>
										<div class="options flex-ac">
											<div class="praise flex-ac" @click="changePraise(i)">
												<img v-show="i.kcc_dz_type == 0" src="../assets/image/icon-praise1.png" alt="" />
												<img v-show="i.kcc_dz_type == 1" src="../assets/image/icon-praise2.png" alt="" />
												<span>{{ i.like }}</span>
											</div>
											<div class="reply-btn" @click="showReplyBox(i, item.id)">回复</div>
										</div>
									</div>
								</div>
								<div class="more-reply-btn" v-if="item.kcc_hfcount != item.hflist.length" @click="showMoreReply(item)">共{{ item.kcc_hfcount }}条回复，点击<span>查看更多</span></div>
								<div class="my-textarea-wrap flex-jc" :id="'textarea' + item.id" v-show="item.showReplyBox">
									<img class="my-avatar" src="https://static.mudu.tv/index/avatar.png" alt="" />
									<textarea class="textarea" maxlength="200" :placeholder="placeholder" v-model.trim="replyUserContent"></textarea>
									<div class="comment-btn flex-jc-ac flex-col" @click="replyUser(item)">
										<div>回复</div>
										<div>评论</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination flex-jc-ac" v-if="courseInfo.kcccountyiji > 10">
					<div class="btn first-pagination" :class="{ disabled: currentPage == 1 }" @click="changeCurrentPage(1)">首页</div>
					<el-pagination
						hide-on-single-page
						next-text="下一页"
						@current-change="getCommentList"
						:current-page.sync="currentPage"
						:page-size="pageSize"
						prev-text="上一页"
						layout="prev, pager, next"
						:total="courseInfo.kcccountyiji || 0"
					>
					</el-pagination>
					<div class="btn end-pagination" :class="{ disabled: currentPage == lastPageCount }" @click="changeCurrentPage(lastPageCount)">尾页</div>
				</div>
			</div>
		</div> -->
		<img src="../assets/image/icon-rocket.png" v-show="showBackTop" @click="toTop" alt="回到顶部" title="回到顶部" class="back-top" />
	</div>
</template>

<script>
import {
	$getCourseInfo,
	$getCommentList,
	$getCommentReplyList,
	$changeCollectStatus,
	$changeSupportStatus,
	$deleteComment,
	$getCommentStatus,
	$submitComment,
	$replyUser,
} from "../services/course.js";
import pdf from "vue-pdf";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import scrollTo from "../utils/scrollToElement";
let ws = null;
import config from "@/utils/env_config.js";
export default {
	name: "Study",
	data() {
		return {
			courseId: "",
			lessonId: "", // 课时id
			title: "",
			courseInfo: {}, // 课时信息
			commentList: [], // 评论列表
			kswdlist: [], // 维度列表
			showpraise: true,
			showComment: false,
			courseComment: "",
			rateExciting: 3, // 精彩度评分
			rateSpeciality: 3, // 专业性评分
			pageSize: 10,
			currentPage: 1,
			placeholder: "您有什么想说的呢？",
			wsTimer: null,
			submitComitLoading: false,
			replyUserContent: "",
			showBackTop: false,
			toReplyerUser: {}, // 被回复人信息
			canSubmitCourseCommit: true,
			pdfPageNum: 1, // pdf 当前页数
			pdfTotalPageNum: 1, // pdf总页数
			swiper: null,
			activeSwiperIndex: 0,
			materialList: [],
		};
	},
	components: {
		pdf,
	},

	computed: {
		lastPageCount() {
			if (!this.courseInfo.kcccountyiji) return 0;
			return Math.ceil(this.courseInfo.kcccountyiji / this.pageSize);
		},
		userInfo() {
			return this.$store.getters.useInfo;
		},
	},

	created() {
		this.lessonId = this.$route.query.id;
		this.courseId = this.$route.query.kcid;
		this.title = this.$route.query.title;
		this.getCourseInfo();
		this.getCommentList();
	},

	mounted() {
		window.addEventListener("scroll", this.handleScroll, true);
	},

	destroyed() {
		window.removeEventListener("scroll", this.handleScroll, true);
		if (ws) {
			clearInterval(this.wsTimer);
			ws.close();
			ws = null;
		}
	},

	methods: {
		initSwiper() {
			let self = this;
			this.swiper = new Swiper(".swiper-container", {
				observer: true,
				observeParents: true,
				//direction: 'vertical', // 垂直切换选项
				//mousewheel: true, //滚轮
				// autoplay: {
				// 	//自动开始
				// 	delay: 2500, //时间间隔
				// 	disableOnInteraction: false, //*手动操作轮播图后不会暂停*
				// },
				loop: false, // 循环模式选项
				// 如果需要前进后退按钮
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				on: {
					slideChange: function () {
						console.log(this);
						self.activeSwiperIndex = this.activeIndex;
					},
				},
			});
		},
		initWebSocket() {
			console.log("准备初始化ws", ws);
			if (ws) return;
			const that = this;
			ws = new WebSocket(config.webSocketUrl);
			// ws = new WebSocket("ws://kctest.meetv.com.cn:8285");
			//连接成功时触发 http://kctest.meetv.com.cn/
			ws.onopen = function () {
				var ksid = that.lessonId; //课时id
				var sid = that.userInfo.id; //用户id
				var login_data = '{"type":"init","data":{"ksid":' + ksid + "}}";
				ws.send(login_data);

				//进入页面
				var message = '{"type":"xxkcusertime","ksid":"' + ksid + '","sid":"' + sid + '"}'; //发送消息给客户端
				ws.send(message); //发送到服务端

				console.log("连接成功");
				that.wsTimer = setInterval(function () {
					keepalive();
				}, 10000); //定时向服务器发送数据包,三十秒一次，最大55秒
			};

			//监听收到发送的消息
			ws.onmessage = function (res) {
				var data = eval("(" + res.data + ")"); //接受服务端的数据
				console.log("接受ws消息：", data);
				let p = that.courseInfo.timebili;
				switch (data["type"]) {
					case "xxkcusertime":
						if (p != data.data.xxbili) {
							that.$set(that.courseInfo, "timebili", data.data.xxbili);
						}
						if (data.data.xxbili == 100 && data.data.kstimetype == 2) {
							clearInterval(that.wsTimer);
							ws.close();
						}
						return;
				}
			};

			ws.onerror = function (res) {
				console.log("err~~~~~~~~~~~~~", res);
			};

			//心跳(勿删，如果55秒内不向客户端发送心跳则会自动断开)
			function keepalive() {
				var ksid = that.lessonId; //课时id
				var sid = that.userInfo.id; //用户id
				var message = '{"type":"xxkcusertime","ksid":"' + ksid + '","sid":"' + sid + '"}'; //发送消息给客户端
				ws.send(message); //发送到服务端
				ws.send('{"type":"ping"}');
			}
		},
		async getCourseInfo() {
			const { data } = await $getCourseInfo({
				ksid: this.lessonId,
			});
			this.courseInfo = JSON.parse(JSON.stringify(data));
			this.materialList = this.courseInfo.kszllist;
			// this.kswdlist = [...data.ksuserwdlist];
			if (this.courseInfo.timebili != 100) {
				this.initWebSocket();
			} else if (this.courseInfo.kstimetype == 1) {
				this.initWebSocket();
			}
			this.$nextTick(() => {
				this.initSwiper();
			});
			// this.swiper.init()
		},
		formatTime(t) {
			var time = new Date(t * 1000);
			var y = time.getFullYear();
			var m = ("0" + (time.getMonth() + 1)).slice(-2);
			var d = ("0" + time.getDate()).slice(-2);
			return `${y}-${m}-${d}`;
		},
		watchVideo() {
			var tarr = [];
			var video = document.querySelector("video");
			video.addEventListener(
				"timeupdate",
				function (e) {
					tarr.unshift(video.currentTime);
					tarr.length = 2;
				},
				false
			);
			video.addEventListener(
				"seeking",
				function (e) {
					if (video.currentTime > tarr[1] && this.courseInfo.timebili != 100) {
						video.currentTime = tarr[1];
					}
				},
				false
			);
		},
		async getCommentList() {
			const { data } = await $getCommentList({
				ksid: this.lessonId,
				page: this.currentPage,
				limit: this.pageSize,
			});
			this.commentList = data;
		},
		async showMoreReply(item) {
			const pid = item.hflist.length > 0 ? item.hflist[0].pid : 0;
			const page = item.replyPage ? item.replyPage + 1 : 2;
			const { data } = await $getCommentReplyList({
				ksid: this.lessonId,
				pid,
				page,
				limit: 5,
			});
			if (data.length > 0) {
				this.$set(item, "replyPage", page);
				item.hflist.push(...data);
			}
		},
		// 改变收藏状态
		async changeCollectStatus() {
			const { data } = await $changeCollectStatus({
				ksid: this.lessonId,
			});
			if (data.sctype == 1) {
				this.$set(this.courseInfo, "ksuscnum", this.courseInfo.ksuscnum + 1);
			} else {
				this.$set(this.courseInfo, "ksuscnum", this.courseInfo.ksuscnum - 1);
			}
			this.$set(this.courseInfo, "sctype", data.sctype);
		},
		// 显示隐藏回复评论框
		showReplyBox(item, id) {
			console.log(item, id);
			this.commentList.forEach(i => {
				if (i.id == id) {
					this.$set(i, "showReplyBox", true);
				} else {
					this.$set(i, "showReplyBox", false);
				}
			});
			this.placeholder = "回复" + item.cname + ":";
			this.toReplyerUser = item;
			this.$nextTick(() => {
				scrollTo("textarea" + id);
			});
		},

		async submitCommit() {
			if (!this.canSubmitCourseCommit) {
				this.$message.error("您现在还不能评论");
				return;
			}
			if (this.submitComitLoading) {
				this.$message.error("提交中，请勿重复提交");
				return;
			}

			if (this.kswdlist.some(i => i.average == 0)) {
				this.$message.error("您还有没有评价没有打分");
				return;
			}
			if (this.courseComment.trim() == "") {
				this.$message.error("您还没有输入评论内容");
				return;
			}
			this.submitComitLoading = true;
			let req = new FormData();
			req.append("ksid", this.lessonId);
			const sid = this.$store.getters.useInfo.id;
			req.append("sid", sid);
			req.append("content", this.courseComment);
			this.kswdlist.forEach(item => {
				req.append(`wdaddlist[${item.id}]`, item.average);
			});
			const { data } = await $submitComment(req);
			this.submitComitLoading = false;
			this.courseComment = "";
			this.getCommentList();
			this.$message.success("评论成功");
			this.$set(this.courseInfo, "ksuser_wdtype", 2);
			this.showComment = false;
			this.$set(this.courseInfo, "kswdlist", data);
		},
		async handleClickCommentBtn() {
			const { data } = await $getCommentStatus({
				ksid: this.lessonId,
			});
			if (data.type == 1) {
				this.$message.error("您还不能评论,请学满课时内容");
				return;
			} else if (data.type == 2) {
				this.canSubmitCourseCommit = true;
			} else {
				this.canSubmitCourseCommit = false;
			}
			if (data.ksuserlist.length > 0) {
				this.kswdlist = data.ksuserlist;
			} else {
				this.kswdlist = data.kswdlist;
			}
			this.showComment = !this.showComment;
		},
		// 点赞状态更改
		async changePraise(item) {
			const { data } = await $changeSupportStatus({
				kccontid: item.id,
			});
			const kcc_dz_type = data.kccdztype == 1 ? 1 : 0;
			const count = data.kccdztype == 1 ? item.like + 1 : item.like - 1;
			this.$set(item, "kcc_dz_type", kcc_dz_type);
			this.$set(item, "like", count);
		},
		async handleDetele(id, index) {
			const { data } = await $deleteComment({
				ksid: this.lessonId,
				kccontid: id,
			});
			if (data == 0) {
				this.$message.success("删除成功");
				this.commentList.splice(index, 1);
				const count = this.courseInfo.kcccountyiji - 1;
				this.$set(this.courseInfo, "kcccountyiji", count);
				// this.$set(this.courseInfo, "ksuserctype", 2);
				if (this.commentList.length == 0 && this.currentPage > 1) {
					this.currentPage--;
					this.getCommentList();
				}
			}
		},
		async replyUser(item) {
			if (!this.replyUserContent) return;
			const data = await $replyUser({
				cid: item.id,
				content: this.replyUserContent,
				ksid: this.lessonId,
				huuserid: this.toReplyerUser.sid,
			});
			this.$message.success("回复成功");
			// this.$set(item, "showReplyBox", false);
			this.getCommentList();
			this.replyUserContent = "";
		},
		changeCurrentPage(currentPage) {
			if (this.currentPage == currentPage) return;
			this.currentPage = currentPage;
			this.getCommentList();
		},
		goToSubjectivePage() {
			this.$router.push({
				path: "/subjectiveWork",
				query: {
					title: this.title,
					kcid: this.courseId,
					id: this.lessonId,
				},
			});
		},
		goToObjectivePage(type) {
			this.$router.push({
				path: "/objectiveWork",
				query: {
					title: this.title,
					kcid: this.courseId,
					id: this.lessonId,
					type,
				},
			});
		},
		back() {
			this.$router.back();
		},
		handleScroll() {
			let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
			scrolltop > 30 ? (this.showBackTop = true) : (this.showBackTop = false);
		},
		toTop() {
			let top = document.documentElement.scrollTop || document.body.scrollTop;
			// 实现滚动效果
			const timeTop = setInterval(() => {
				document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
				if (top <= 0) {
					clearInterval(timeTop);
				}
			}, 10);
		},
		prevPagePDF() {
			var p = this.pdfPageNum;
			if (p > 1) {
				this.pdfPageNum = p - 1;
			}
		},
		nextPagePDF() {
			var p = this.pdfPageNum;
			if (p < this.pdfTotalPageNum) {
				this.pdfPageNum = p + 1;
			}
		},
		prevPage() {
			// var p = this.pdfPageNum;
			// if (p > 1) {
			// 	this.pdfPageNum = p - 1;
			// }
			this.swiper.slidePrev();
		},
		nextPage() {
			// var p = this.pdfPageNum;
			// if (p < this.pdfTotalPageNum) {
			// 	this.pdfPageNum = p + 1;
			// }
			this.swiper.slideNext();
		},
	},
};
</script>

<style scoped lang="less">
.study-page {
	min-height: calc(100vh - 235px);
}
.nav-content {
	height: 52px;

	background: #f3f5f6;
	.nav-title {
		width: 1200px;
		height: 52px;
		margin: 0 auto;
		font-size: 16px;
		color: #1c1f21;
		.back {
			width: 120px;
			height: 37px;
			border-radius: 20px;
			color: #fff;
			margin-right: 20px;
			background-color: rgba(153, 153, 153, 1);
			// background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
			cursor: pointer;
		}
	}
}
.course-content-wrap {
	background: #4d555d;
	color: #f3f5f6;
	.course-content {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 58px;
		.title-contianer {
			height: 150px;
			.left {
				padding-top: 32px;
				.title {
					font-size: 36px;
					color: #f3f5f6;
				}
				.info {
					margin-top: 15px;
					font-size: 14px;
					.view {
						line-height: 16px;
						margin-right: 40px;
						img {
							height: 16px;
							margin-right: 16px;
							&.praise {
								cursor: pointer;
							}
						}
					}
				}
			}
			.right {
				padding-top: 50px;
				line-height: 16px;
				.time {
					margin-bottom: 20px;
					text-align: center;
					padding-right: 20px;
				}
				.step-wrap {
					width: 200px;
					height: 16px;
					background: rgba(255, 255, 255, 0.5);
					border-radius: 8px;
					overflow: hidden;
					margin: 0 18px;
					.step {
						height: 16px;
						background: linear-gradient(90deg, #81e887 0%, #46d5f4 100%);
						opacity: 0.96;
					}
				}
			}
		}
		.video-container {
			width: 1200px;
			height: 628px;
			#player {
				width: 1200px;
				height: 628px;
			}
		}
		.btn-wrap {
			width: 1200px;
			height: 135px;
			padding-top: 43px;
			.btn {
				width: 178px;
				height: 54px;
				line-height: 54px;
				text-align: center;
				background-color: rgba(255, 255, 255, 0.3);
				border-radius: 27px;
				margin-right: 242px;
				background-color: rgba(255, 255, 255, 0.5);
				cursor: pointer;
				user-select: none;
				&.active {
					background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.introduction-container {
			width: 1200px;
			background-color: rgba(255, 255, 255, 0.1);
			padding: 40px 38px 60px 26px;
			font-size: 16px;
			line-height: 26px;
			color: #ffffff;
			.title {
				font-size: 22px;
				margin-bottom: 20px;
			}
			.content /deep/ img {
				max-width: 1136px;
			}
		}
	}
}
.comment-container {
	.nav-content {
		width: 100%;
		height: 100px;
		background: #f3f5f6;
		.comment-nav {
			height: 100%;
			width: 1200px;
			margin: 0 auto;
			font-size: 22px;
			color: #1c1f21;
			.comment-btn {
				cursor: pointer;
				user-select: none;
				width: 102px;
				height: 36px;
				background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				border-radius: 18px;
				line-height: 36px;
				text-align: center;
				font-size: 13px;
				color: #ffffff;
			}
		}
	}
	.comment-wrap {
		padding-top: 28px;
		.comment-content {
			width: 1200px;
			margin: 0 auto;
			border-radius: 8px;
			box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.2);
			margin-bottom: 40px;
			padding: 40px;
			.my-comment {
				border-bottom: 1px solid #eeefef;
				padding-bottom: 40px;
				margin-bottom: 40px;
				.rate-wrap {
					.rate {
						margin-right: 70px;
						font-size: 18px;
						line-height: 24px;
						color: #1c1f21;
						white-space: nowrap;
						&:last-child {
							margin-right: 0;
						}
						.el-rate {
							margin-left: 15px;
							/deep/ .el-rate__icon {
								font-size: 22px;
							}
						}
					}
				}

				.my-textarea-wrap {
					margin-top: 40px;
					.textarea {
						resize: none;
						padding: 11px 15px;
						width: 858px;
						height: 80px;
						background-color: rgba(243, 245, 246, 0.8);
						border: none;
						outline: none;
					}
					.comment-btn {
						width: 80px;
						height: 80px;
						text-align: center;
						line-height: 80px;
						background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
						border-radius: 4px;
						font-size: 16px;
						color: #ffffff;
						margin-left: 10px;
						cursor: pointer;
						user-select: none;
					}
				}
			}
			.course-rate {
				font-size: 24px;
				.rate {
					width: 50%;
					margin: 10px 0;
				}
				.el-rate {
					margin-left: 10px;
					height: 30px;
					/deep/ .el-rate__icon {
						font-size: 30px;
					}
				}
			}
			.comment-list {
				// width: 1120px;
				margin-top: 45px;
				.comment {
					border-top: 1px solid #eeefef;
					padding: 50px 0;
					.avatar {
						width: 58px;
						height: 58px;
						border-radius: 50%;
						margin-right: 34px;
					}
					.content {
						flex: 1;
						.info {
							font-size: 15px;
							color: #7c899a;
							.name {
								font-size: 20px;
								font-weight: bold;
								color: #1c1f21;
							}
							.options {
								.praise {
									cursor: pointer;
									img {
										width: 16px;
										height: 16px;
										margin-right: 16px;
									}
								}
								.detele {
									margin-left: 43px;
									cursor: pointer;
								}
							}
						}
						.txt {
							font-size: 20px;

							color: #1c1f21;
							margin: 30px 0;
							line-height: 30px;
						}
						.rate-wrap {
							color: #8c9094;
							font-size: 14px;
							margin-bottom: 35px;
							flex-wrap: wrap;
							.rate {
								margin-right: 40px;
								.el-rate {
									margin-left: 10px;
								}
							}
						}
						.time-wrap {
							font-size: 13px;
							color: #7c899a;
							.reply {
								font-size: 15px;
								cursor: pointer;
								user-select: none;
								text-decoration: underline;
							}
						}
						.reply-list-container {
							padding-top: 15px;
							margin-top: 15px;
							border-top: 1px solid #eeefef;
							.replyer-avatar {
								width: 58px;
								height: 58px;
								border-radius: 50%;
								margin-right: 33px;
								flex: none;
							}
							.reply-list {
								.reply-content {
									width: 930px;
									line-height: 28px;
									color: #7c899a;
									font-size: 16px;
									.name {
										color: #3382ff;
									}
									.reply-txt {
										color: #1c1f21;
									}
									pre {
										line-height: 20px;
									}
								}
								.time {
									color: #7c899a;
									margin: 10px 0;
								}
								.options {
									margin-top: 20px;
									color: #7c899a;
									.praise {
										margin-right: 45px;
										cursor: pointer;
										img {
											width: 14px;
											margin-right: 12px;
										}
									}
									.reply-btn {
										cursor: pointer;
										text-decoration: underline;
									}
								}
							}
						}
						.reply-comment {
							margin-top: 30px;
							padding-top: 18px;
							border-top: 1px solid #eeefef;
							.replyer-avatar {
								width: 58px;
								height: 58px;
								border-radius: 50%;
								margin-right: 25px;
							}
							.reply-content-wrap {
								.reply-content {
									font-size: 15px;
									color: #1c1f21;
									line-height: 30px;
									span {
										color: #3382ff;
									}
								}
								.praise-wrap {
									margin-top: 25px;
									cursor: pointer;
									color: #7c899a;
									.praise {
										margin-right: 45px;
										img {
											width: 14px;

											margin-right: 12px;
										}
									}
								}
							}
						}
						.more-reply-btn {
							margin-top: 20px;
							color: #7c899a;
							span {
								color: #3382ff;
								cursor: pointer;
							}
						}
						.my-textarea-wrap {
							margin-top: 35px;
							.my-avatar {
								width: 58px;
								height: 58px;
								border-radius: 50%;
								margin-right: 25px;
							}
							.textarea {
								resize: none;
								padding: 11px 15px;
								width: 858px;
								height: 80px;
								background-color: rgba(243, 245, 246, 0.8);
								border: none;
								outline: none;
							}
							.comment-btn {
								width: 80px;
								height: 80px;
								text-align: center;
								background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
								border-radius: 4px;
								font-size: 16px;
								color: #ffffff;
								margin-left: 10px;
								cursor: pointer;
								user-select: none;
							}
						}
					}
				}
			}
		}
	}
	.pagination {
		margin: 60px 0;
		font-size: 15px;
		color: #4d555d;
		.btn {
			cursor: pointer;
			&.disabled {
				color: #c0c4cc;
				cursor: not-allowed;
			}
		}
		.el-pagination {
			margin: 0 10px;
			display: flex;
			align-items: center;
			/deep/ span,
			/deep/ li {
				font-size: 15px;
				font-weight: normal;
			}
			/deep/ .el-pager li {
				line-height: 36px;
				&.active {
					color: #fff;
					width: 36px;
					height: 36px;
					background: #4d555d;
					border-radius: 50%;
				}
			}
		}
	}
}
video.hide::-webkit-media-controls-timeline {
	display: none;
}
.back-top {
	position: fixed;
	right: 100px;
	bottom: 100px;
	width: 100px;
	cursor: pointer;
}
pre {
	display: inline-block;
	font-family: sans-serif;
	white-space: pre-wrap;
	word-wrap: break-word;
	vertical-align: text-top;
}
.pdf-file-container {
	margin: 50px 0;
	.pdf-file {
		height: 100%;
		position: relative;
		.pdf-prev {
			position: absolute;
			left: 0;
			top: 0;
			width: 30%;
			height: 100%;
			z-index: 9;
			cursor: pointer;
		}
		.pdf-next {
			position: absolute;
			right: 0;
			top: 0;
			width: 30%;
			height: 100%;
			z-index: 9;
			cursor: pointer;
		}
	}
}
.btn-list {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
	.pdf-btn {
		margin: 0 50px;
		width: 178px;
		height: 54px;
		line-height: 54px;
		text-align: center;
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: 27px;
		background-color: rgba(255, 255, 255, 0.5);
		cursor: pointer;
		user-select: none;
	}
	.pagination {
		color: #f3f5f6;
		font-size: 26px;
		margin: 0 40px;
	}
}
.swiper_img {
	width: 1200px;
}
.prohibit {
	cursor: not-allowed !important;
}
/deep/ .swiper_prev {
	top: 0;
	margin: 0;
	height: 100%;
	width: 0.6rem;
	background: transparent;
}
/deep/ .swiper_next {
	top: 0;
	right: 0;
	margin: 0;
	height: 100%;
	width: 0.6rem;
	background: transparent;
}
.material-list-wrap {
	margin-top: 50px;
	.material-title {
		font-size: 22px;
		color: #ffffff;
		font-weight: bold;
	}
	.material-list {
		padding-top: 25px;
		.item {
			padding: 20px 0;
			font-size: 18px;
			color: #ffffff;
			border-bottom: 1px solid #8c9094;
		}
		.btn {
			width: 80px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			font-size: 16px;
			color: #ffffff;
			background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
			border-radius: 15px;
			user-select: none;
			cursor: pointer;
		}
	}
}
</style>
